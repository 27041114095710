<template>
  <v-app>
    <menu-bar />
    <content-View />
    <features-view />
    <services-info />
    <banner-view />
    <plan-view />
    <footer-view />
  </v-app>
</template>

<script>
export default {
  components: {
    MenuBar: () => import('@/components/MenuBar'),
    ServicesInfo: () => import('@/components/ServicesInfo'),
    ContentView: () => import('@/components/ContentView'),
    BannerView: () => import('@/components/BannerView'),
    FeaturesView: () => import('@/components/FeaturesView'),
    PlanView: () => import('@/components/PlanView'),
    FooterView: () => import('@/components/Footer')
  }
};
</script>

<style lang="sass">

</style>